<template>
  <div class="product-list__header-categories">
    <div class="category-list category-list--horizontal">
      <Swiper
        :modules="[Navigation]"
        :speed="400"
        slidesPerView="auto"
        :slidesPerGroup="2"
        :roundLengths="true"
        :navigation="true"
      >
        <SwiperSlide
          v-for="category in quickFilters()"
          :key="category.id"
          class="category-list__item"
          :class="{ 'category-list__item--current': parseInt(category.id) === parseInt(activeCategoryId) }"
        >
          <SfLink :link="category.url">{{ category.name }}</SfLink>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation } from 'swiper/modules'

const props = defineProps({
  categories: {
    type: Array,
    default: [],
  },
  activeCategoryId: {
    type: [String, Number],
    default: 0,
  },
})

const isMounted = ref(false)

onMounted(() => (isMounted.value = true))

const { quickFilters: storeQuickFilters } = usePageStore()

const quickFilters = () => {
  let filters: any[] = props.categories
  if (storeQuickFilters.length) {
    filters = filters.filter((filter) => storeQuickFilters?.some((pageFilter) => pageFilter.id == filter.id))
  }
  return filters
}
</script>

<style lang="scss">
.product-list__header-categories {
  grid-row: 2 / 3;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  @include for-desktop {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }

  .category-list--horizontal {
    width: 100%;

    @include for-mobile {
      display: flex;
      min-height: 54px;
    }

    .swiper {
      margin: 0;
      width: 100%;

      @include for-mobile {
        --swiper-offset-before: 16px;
        --swiper-offset-after: 16px;
      }

      @include for-desktop {
        margin: 0;
        padding: 8px 0;
      }

      .swiper-wrapper {
        margin: 0;
        padding: 0;

        .swiper-slide {
          --link-font-family: var(--font-family--primary);
          --link-font-weight: var(--font-weight--normal);
          --link-font-size: var(--global-font-size);
          --link-text-decoration: none;

          display: flex;
          align-items: center;
          width: auto;
          white-space: nowrap;

          &:hover,
          &.category-list__item--current {
            --link-text-decoration: underline;
          }

          &.category-list__item--current a:hover {
            cursor: default;
          }

          .sf-link {
            display: block;
            color: var(--black-color);
            line-height: var(--line-height-18);

            @include for-desktop {
              line-height: var(--global-line-height);
            }

            &:visited {
              color: var(--black-color);
            }
          }

          .swiper-slide-transform {
            display: flex;
            align-items: center;

            @include for-mobile {
              margin-left: var(--spacer-sm);
            }

            @include for-desktop {
              min-height: 40px;
              margin-right: var(--spacer-sm);
            }
          }

          &:first-child {
            .swiper-slide-transform {
              @include for-mobile {
                padding-left: 0;
              }
            }
          }

          &:last-child {
            .swiper-slide-transform {
              @include for-desktop {
                margin-right: var(--spacer-sm);
              }
            }
          }
        }
      }

      .sf-button {
        top: 50%;
        transform: translateY(-50%);
        margin: 0;

        &:after {
          font-size: 16px;
          color: var(--black-color);
        }

        &.swiper-button-next {
          right: 0;
        }

        &.swiper-button-prev {
          left: 0;
        }

        @media (max-width: 542px) {
          display: none;
        }
      }
    }
  }
}
</style>
