type Props = {
  widgetType: 'globalRecommendationWidget'
  widgetId: 'string'
}

const queryFields = [
  'pid',
  'sku',
  'title',
  'url',
  'brand',
  'original_price',
  'price',
  'sale_price',
  'colors',
  'style_swatches_hex',
  'active_colors_hex',
  'sizes',
  'product_label',
  'thumb_image',
  'thumbnail',
  'hover_image_url',
  'out_of_stock',
  'date_online',
  'reviews_rating_summary',
  'review_count',
  'color',
  'filter_image',
  'sku_color',
  'sku_size',
]

const WIDGET_TYPES_URL_SLUG = {
  bestSeller: 'global',
  trendingProducts: 'global',
  //will update based on new widget types
}

export const useBloomreachWidgetsApi = () => {
  const logger = useAppLogger('useBloomreachDiscoveryStore')

  const {
    public: {
      bloomreachDiscovery: { authKey, accountId, domainKey },
      storeUrl,
    },
  } = useRuntimeConfig()

  const {
    $i18n: { locale },
  } = useNuxtApp()

  const getWidgetProducts = async ({ widgetType, widgetId }: Props) => {
    const { routeData } = usePageStore()
    const requestId = 'id-' + Math.random().toString(16).slice(2)

    const currentDomain = ref('')
    domainKey.split(',').forEach((k) => {
      if (k.startsWith(locale.value.substring(0, 2))) {
        currentDomain.value = k.split('=')[1]
      }
    })

    const query = {
      account_id: accountId,
      domain_key: currentDomain.value,
      request_id: requestId,
      url: `${storeUrl}/${routeData.relative_url}`,
      _br_uid_2: useCookie('_br_uid_2').value,
      fields: queryFields.join(','),
    }

    const queryString = Object.keys(query)
      .map((key) => {
        return `${key}=${query[key]}`
      })
      .join('&')
      .replaceAll('%2B', '+')

    try {
      const { response } = await $fetch(
        `https://pathways-staging.dxpapi.com/api/v2/widgets/${WIDGET_TYPES_URL_SLUG[widgetType]}/${widgetId}?${queryString}`,
        {
          headers: {
            'auth-key': authKey,
          },
        },
      )

      return response.docs
    } catch (error) {
      logger.error(error)
      return []
    }
  }

  return {
    getWidgetProducts,
  }
}

export default useBloomreachWidgetsApi
